import 'styles/index.scss';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { HashRouter } from 'react-router-dom';

import { ChakraProvider } from '@chakra-ui/react';
import { RecoilRoot } from 'recoil';

import './i18n';
import { App } from './App';
import reportWebVitals from './reportWebVitals';

import theme from 'theme';
import { ToastContextProvider } from 'utils/context/toastContext';
import { UserAuthContextProvider } from 'utils/context/userContext';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <HashRouter>
        <ToastContextProvider>
          <RecoilRoot>
            <UserAuthContextProvider>
              <App />
            </UserAuthContextProvider>
          </RecoilRoot>
        </ToastContextProvider>
      </HashRouter>
    </ChakraProvider>
  </React.StrictMode>
);

reportWebVitals();
