import { Box, Flex } from '@chakra-ui/react';
import 'styles/components/_layoutHeader.scss';

interface Props {
  leftContent?: JSX.Element;
  rightContent?: JSX.Element;
}

export default function Header(props: Props) {
  const { leftContent, rightContent } = props;

  return (
    <Box
      as="header"
      position="fixed"
      width="100vw"
      height="60px"
      zIndex={1}
      pl="20px"
      bg="mainColor.darkest">
      <Flex h="60px" alignItems={'center'} justifyContent={'space-between'}>
        <Flex h="100%" alignItems={'center'} gap="18px">
          {leftContent}
        </Flex>

        <Flex h="100%" alignItems={'center'}>
          {rightContent}
        </Flex>
      </Flex>
    </Box>
  );
}
