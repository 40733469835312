/* eslint-disable react-hooks/exhaustive-deps */
import { Box } from '@chakra-ui/react';
import { useAppRouters } from 'routing';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { useEffect } from 'react';
import { useToast } from 'utils/hooks/useToast';
import { playNotificationSound } from 'utils/playNotificationSound';
import { onMessageListener } from 'utils/FirebaseMessaging';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import useInterval from 'utils/hooks/useInterval';
import { GlobalConstant } from 'static/constants';
import { useRecoilStateLoadable } from 'recoil';
import { notificationListState } from 'recoil/selectedStore/notification.atom';
import { useAuth } from 'utils/hooks';
import { useSingleAndDoubleClick } from 'utils/hooks/useSingleDoubleClick';

navigator.serviceWorker.addEventListener(
  'message',
  () => {
    playNotificationSound();
  },
  false
);

export const App = () => {
  const route = useAppRouters();
  const { showToast } = useToast();
  const [notifications] = useRecoilStateLoadable(notificationListState);
  const { user } = useAuth();

  const triggerSoundNotification = useSingleAndDoubleClick(
    playNotificationSound,
    undefined,
    null,
    1000
  );

  const hasNewOrder =
    notifications?.contents?.length > 0 &&
    notifications?.contents?.some((notification: any) => notification?.type === 'New Order');

  const messageListener = async () => {
    onMessageListener().then((payload) => {
      showToast({
        title: payload.notification?.title,
        status: 'success',
        description: payload.notification?.body,
        fullWidth: true
      });
    });
  };
  messageListener();

  useEffect(() => {
    dayjs.extend(advancedFormat);
  }, []);

  useInterval(
    triggerSoundNotification,
    GlobalConstant.NOTIFICATION_REMINDER,
    false,
    !hasNewOrder || !!!user?.uid
  );

  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DNS,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0
    // debug: true
  });
  // Sentry.addGlobalEventProcessor(function (event, hint) {
  //   // Add anything to the event here
  //   // returning `null` will drop the event
  //   return event;
  // });

  return (
    <Box className="App" height="100vh" role="app">
      {route}
    </Box>
  );
};
