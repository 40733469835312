const typography = {
  fontWeights: {
    light: 300,
    regular: 400,
    bold: 700,
  },

  fontSizes: {
    sm: '0.75rem', //12px
    md: '0.875rem', //14px
    lg: '1rem', //16px
    xl: '1.25rem', //20px
    '2xl': '1.625rem', //26px
    '3xl': '2rem', //32px
  },
};

export default typography;
