/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilStateLoadable } from 'recoil';
import { selectedStoreIdState } from 'recoil/selectedStore/selectedStore.atom';
import { HeaderIconButton } from './header';
import { ReactComponent as BellIcon } from 'assets/icons/icon-bell.svg';
import { fetchUserNotifications } from 'service/OrderDetailsApi';
import { useAuth } from 'utils/hooks';
import { notificationListState } from 'recoil/selectedStore/notification.atom';

export default function NotificationIcon() {
  const navigation = useNavigate();
  const [selectedStore] = useRecoilState(selectedStoreIdState);
  const [notifications, setNotifications] = useRecoilStateLoadable(notificationListState);
  const [isLoading, setLoader] = useState(false);
  const { user } = useAuth();

  function fetchNotification(uid: string, storeId: string) {
    return fetchUserNotifications(
      uid,
      storeId,
      async (notifications) => {
        setNotifications(notifications ?? []);
        setLoader(false);
      },
      (error) => {
        console.error(error);
        setLoader(false);
      }
    );
  }

  useEffect(() => {
    if (user?.uid && selectedStore?.id) {
      setLoader(true);
      const unsubscribe = fetchNotification(user?.uid || '', selectedStore?.id);

      return () => unsubscribe();
    }
  }, [selectedStore?.id, user?.uid]);

  function updateUserStoreNotifications() {
    if (!user?.uid) return;
    if (selectedStore?.id) {
      navigation(`/stores/${selectedStore?.id}/orders`, {
        replace: true
      });
    } else {
      navigation(`/stores`, {
        replace: true
      });
    }
  }

  return (
    <HeaderIconButton
      isLoading={isLoading}
      badgeCount={notifications.contents.length}
      onClick={updateUserStoreNotifications}>
      <BellIcon height="2rem" />
    </HeaderIconButton>
  );
}
