import React, { memo } from 'react';
import { IconContext } from 'react-icons';
import { AttributeProps, StyleProps } from 'static/types/GenericTypes';
import loadable from '@loadable/component';

export interface DynamicIconImportProps {
  icon: string;
  ic_color?: string;
  ic_size?: string;
  ic_className?: string;
}

interface DynamicIconProps extends StyleProps, AttributeProps, DynamicIconImportProps {}

export const DynamicIcon = ({
  icon,
  ic_color,
  ic_size,
  ic_className,
  style,
  attr,
  ...rest
}: DynamicIconProps) => {
  const [library, iconComponent] = icon.split('/');

  if (!library || !iconComponent) return <div>[]</div>;

  const lib = library.toLowerCase();
  const Icon = loadable(() => import(`react-icons/${lib}/index.js`), {
    resolveComponent: (el: JSX.Element) => el[iconComponent as keyof JSX.Element]
  });

  const value: IconContext = {
    color: ic_color,
    size: ic_size,
    className: ic_className,
    style: style,
    attr: attr
  };

  return (
    <IconContext.Provider value={value}>
      <Icon data-testid="icon" />
    </IconContext.Provider>
  );
};

export default memo(DynamicIcon);
