import { Box } from '@chakra-ui/react';
import { ReactComponent as StandardLogo } from 'assets/logo-standard.svg';
import { useNavigate } from 'react-router-dom';

export default function BrandingLogo() {
  const navigate = useNavigate();
  const gotoHome = () => {
    navigate('/');
  };
  return (
    <Box
      alignItems="center"
      pl="5px"
      onClick={gotoHome}
      data-testid="branding-logo"
      cursor="pointer"
      title="Return to home">
      <StandardLogo />
    </Box>
  );
}
