import { BrandingLogo } from 'components/brandingLogo';
import { Header } from 'components/header';
import Popup from 'components/Popout/Popout';
import HamMenu from 'components/HamMenu/HamMenu';
import { ReactComponent as AccountIcon } from 'assets/icons/icon-account.svg';
import { useMemo } from 'react';
import { useAuth } from 'utils/hooks';
import NotificationIcon from 'components/NotificationIcon';

export default function AppHeader() {
  return <Header leftContent={<HeaderLeftContent />} rightContent={<HeaderRightContent />} />;
}

function HeaderLeftContent() {
  return (
    <>
      <HamMenu />
      <BrandingLogo />
    </>
  );
}

function HeaderRightContent() {
  const { logOut, profile } = useAuth();
  const ACCOUNT_POPUP = useMemo(
    () => [
      {
        value: profile?.name ?? '',
        style: {
          color: 'text.secondary'
        },
        onClick: () => {}
      },
      {
        value: 'Sign out',
        onClick: () => logOut(),
        style: {
          color: 'text.primary',
          _hover: {
            color: '#0245ff'
          }
        }
      }
    ],
    [logOut, profile]
  );
  return (
    <>
      <NotificationIcon />

      <Popup items={ACCOUNT_POPUP}>
        <AccountIcon height="2rem" id="header-avatar" />
      </Popup>
    </>
  );
}
