//all button overrides

export const buttonTheme = {
  baseStyle: {
    fontWeight: 'bold',
    borderRadius: '3px',
    fontSize: 'lg',
    lineHeight: 'normal',
    minWidth: '4',
  },
  variants: {
    solid: {
      bg: 'mainColor.base',
      color: 'white',
      boxShadow: '0 2px 4px 0 #1a385180',
    },
    secondary: {
      bg: 'white',
      color: 'mainColor.base',
      border: '1px',
      borderColor: 'mainColor.base',
      fontWeight: 'regular',
    },
    inactive: {
      bg: 'background.3',
      color: 'text.secondary',
      boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.5)',
    },
    'secondary-inactive': {
      bg: 'white',
      color: 'text.secondary',
      border: '1px',
      borderColor: 'grey.base',
      fontWeight: 'regular',
    },
  },
  sizes: {
    md: {
      h: '2.375rem',
      px: '1.4rem',
    },
    lg: {
      h: '2.375rem',
      px: '2.3rem',
    },
  },
  defaultProps: {
    size: 'md',
    colorScheme: 'mainColor.base',
  },
};
