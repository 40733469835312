const date = new Date();

const {
  REACT_APP_APP_NAME,
  REACT_APP_TOAST_DURATION,
  REACT_APP_TABLE_PAGINATION_LIMIT,
  REACT_APP_NOTIFICATION_REMINDER,
  REACT_APP_DEPLOY_ENVIRONMENT
} = process.env;

export const GlobalConstant = {
  GENERIC_FOOTER_HEADER: `Copyright © ${date.getFullYear()} Wynshop.`,
  GENERIC_FOOTER_SUBHEADER: `All Rights Reserved.`,
  APP_NAME: REACT_APP_APP_NAME ?? 'Web picking application',
  MENU_ITEM: [
    { name: 'Stores', path: '/stores' },
    { name: 'Orders', path: '/orders' }
  ],
  TOAST_DURATION: parseInt(REACT_APP_TOAST_DURATION!) ?? 5000,
  LIMIT: parseInt(REACT_APP_TABLE_PAGINATION_LIMIT!) ?? 100,
  NEW_ORDER_TIMESTAMP: 1,
  SCROLL_Y_POSITION: 100,
  TRANSITION_DURATION: 0.5,
  NOTIFICATION_REMINDER: parseInt(REACT_APP_NOTIFICATION_REMINDER!) ?? 1000 * 60 * 30, //30 minutes
  DEPLOY_ENVIRONMENT: REACT_APP_DEPLOY_ENVIRONMENT ?? 'wps'
};
