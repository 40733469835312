import { getMessaging, getToken, MessagePayload, onMessage } from 'firebase/messaging';
// import api from 'service/axiosInterceptor';
import { addUserFcmToken, fetchUserFcmTokens } from 'service/OrderDetailsApi';
import { app, auth } from './Firebase';

// let messaging = getMessaging(app);

export function getMessagingToken() {
  return new Promise<string>((resolve, reject) => {
    getToken(getMessaging(app), {
      vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY
    })
      .then((currentToken) => {
        if (currentToken) {
          auth.onAuthStateChanged((user) => {
            let fsmTokens: string[] = [];
            fetchUserFcmTokens(user?.uid)
              .then((data) => {
                fsmTokens = data ?? [];
              })
              .finally(() => {
                fsmTokens = Array.from(new Set([...fsmTokens, currentToken]));
                addUserFcmToken(fsmTokens, user?.uid);
              });

            resolve(currentToken);
          });
        } else reject('No registration token available. Request permission to generate one.');
      })
      .catch((err) => reject(err));
  });
}

function requestPermission() {
  if (typeof Notification !== 'undefined') {
    Notification.requestPermission()
      .then((permission) => {
        if (permission === 'granted') {
          getMessagingToken();
        } else {
          console.warn('Do not have a notification permission.');
        }
      })
      .catch((e) => {
        console.error(e);
      });
  }
}
requestPermission();

// function getMessagingToken() {
//   return new Promise<string>((resolve, reject) => {
//     getToken(getMessaging(app), {
//       vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY
//     })
//       .then((fcmToken) => {
//         if (fcmToken)
//           auth.onAuthStateChanged(async (user) => {
//             if (user?.uid)
//               await api.post('/api/register-fcm-token', { fcmToken }).then(() => resolve(fcmToken));
//           });
//         else reject('No registration token available. Request permission to generate one.');
//       })
//       .catch((err) => reject(err));
//   });
// }

export const onMessageListener = () =>
  new Promise<MessagePayload>((resolve) => {
    onMessage(getMessaging(app), (payload) => {
      resolve(payload);
    });
  });
