import {
  User,
  browserSessionPersistence,
  setPersistence,
  signInWithCustomToken
} from 'firebase/auth';
import { auth } from 'utils/Firebase';
import * as Sentry from '@sentry/react';
import { UserProfileT } from 'utils/context/userContext';
import api from './axiosInterceptor';

export function firebaseAuthentication(onCompleted: (profile: UserProfileT | null) => void) {
  Sentry.setUser(null);
  sessionStorage.clear();
  setPersistence(auth, browserSessionPersistence);
  return auth.onAuthStateChanged(async function (user) {
    if (user) await verifyIdToken(user).then((profile) => onCompleted(profile));
    else {
      await api
        .get<{ access_token: string }>('/api/auth/generate-custom-token', api.getCancelToken())
        .then(
          async ({ data: { access_token } }) => await signInWithCustomToken(auth, access_token)
        );
    }
  });
}

export async function verifyIdToken(user: User) {
  return await user.getIdTokenResult().then(
    async (value) =>
      await api
        .get<UserProfileT>('/api/auth/verify-id-token', {
          params: { id_token: value.token },
          ...api.getCancelToken()
        })
        .then(({ data }) => data)
  );
}

export async function signOutUser() {
  Sentry.setUser(null);
  sessionStorage.clear();
  window.location.href = `/api/auth/logout`;
}
