import { AlertStatus, useToast } from '@chakra-ui/react';
import { ToastRenderer } from 'components/CustomToast';
import React, { createContext } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { GlobalConstant } from 'static/constants';
import { StyleProps } from 'static/types/GenericTypes';

type ToastContextProps = {
  children: React.ReactNode;
};

interface ShowProps extends StyleProps {
  title?: string;
  description?: string;
  status?: AlertStatus;
  actionButton?: React.ReactNode;
  fullWidth?: boolean;
  isClosable?: boolean;
}

interface ToastContextInterface {
  showToast: (props: ShowProps) => void;
}

export const ToastContext = createContext<ToastContextInterface>({
  showToast: (props: ShowProps) => {}
});

export const ToastContextProvider = ({ children }: ToastContextProps) => {
  const toast = useToast({
    duration: GlobalConstant.TOAST_DURATION,
    isClosable: true,
    render: (props) => <ToastRenderer {...props} />
  });
  const { pathname } = useLocation();

  return (
    <ToastContext.Provider
      value={{
        showToast: ({
          description = '',
          title = '',
          status = 'success',
          isClosable = true,
          ...rest
        }) => {
          toast({
            title,
            description,
            status,
            ...rest,
            containerStyle: {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              maxWidth: !!rest?.fullWidth ? '100vw' : 'unset',
              margin: '0',
              padding: '0',
              position: "absolute",
              bottom: matchPath('/stores/:storeId/orders/:orderId', pathname) ? {sm: "160px", md: '100px'} : {sm: "10px"}
            },
          });
        }
      }}>
      {children}
    </ToastContext.Provider>
  );
};
