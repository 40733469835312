import { useEffect, useRef } from 'react';

export function useInterval(
  callback: () => void,
  delay: number | null | false,
  immediate?: boolean,
  discard?: boolean
) {
  const savedCallback = useRef(() => {});

  useEffect(() => {
    savedCallback.current = discard ? () => {} : callback;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [discard]);

  useEffect(() => {
    if (!immediate || !delay) return;
    savedCallback.current();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [immediate]);

  useEffect(() => {
    if (!delay) return;
    const tick = () => savedCallback.current();
    const id = setInterval(tick, delay);
    return () => clearInterval(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [delay]);
}

export default useInterval;
