export const routePaths = {
  login: {
    path: '/login',
    name: 'Login',
  },
  welcome: {
    path: '/',
    name: 'Welcome',
  },
  orders: {
    path: '/stores/:storeId/orders',
    name: 'Orders',
  },
  orderDetails: {
    path: 'stores/:storeId/orders/:orderId',
    name: 'Order Details',
  },
  storeList: {
    path: '/stores',
    name: 'Store List',
  },
  resourceNotFound: {
    path: '*',
    name: 'Resource Not Found',
  },

  printOrderSummary: {
    path: '/print-order-summary',
    name: 'Print Order Summary',
  },
};
