import {
  Alert,
  AlertDescription,
  AlertTitle,
  CloseButton,
  Flex,
  UseToastOptions
} from '@chakra-ui/react';
import React from 'react';

interface ToastRendererProps extends UseToastOptions {
  actionButton?: React.ReactNode;
  fullWidth?: boolean;
  onClose(): void;
}

const statusToColor = {
  bg: {
    info: 'info.base',
    warning: 'warning[400]',
    success: 'progress.base',
    error: 'error.light',
    loading: 'notification.light'
  },
  text: {
    info: 'active.base',
    warning: 'text.primary',
    success: 'progress.darker',
    error: 'error.dark',
    loading: 'notification.base'
  }
};

export const ToastRenderer = ({
  title,
  description,
  onClose,
  position,
  id,
  status = 'error',
  actionButton,
  isClosable = true,
  containerStyle,
  fullWidth = false,
  render,
  ...rest
}: ToastRendererProps) => {
  return (
    <Alert
      status={status}
      bgColor={statusToColor.bg[status]}
      textColor={statusToColor.text[status]}
      width={{ sm: '100vw', md: fullWidth ? '98vw' : 'unset' }}
      paddingTop={0.5}
      paddingBottom={0.5}
      maxW={'100%'}
      {...rest}
      bottom="0px !important">
      <Flex flex={1}>
        <AlertTitle
          fontWeight="bold"
          fontSize="md"
          textTransform="capitalize"
          alignItems="center"
          me={1.5}>
          {title}:
        </AlertTitle>
        <AlertDescription fontSize="md">{description}</AlertDescription>
        {actionButton}
      </Flex>

      <CloseButton data-testid="close_btn" onClick={onClose} ml={2} mr={-2} />
    </Alert>
  );
};
