import React, { memo } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from 'utils/hooks';
import { routePaths } from './routePaths';
import * as Sentry from '@sentry/react';
import PageLoader from 'components/pageLoader/PageLoader';

type RouteGuardProps = {
  revertAuthCheck?: boolean;
  children?: React.ReactNode;
  to?: string;
  isProtected?: boolean;
};

const RouteGuard = ({
  revertAuthCheck = false,
  children,
  to = routePaths.welcome.path,
  isProtected = true
}: RouteGuardProps) => {
  const { user, isLoading } = useAuth();

  if (user?.uid)
    Sentry.setUser({
      id: user?.uid,
      name: user?.displayName
    });

  if (isProtected) {
    if (user?.uid && !isLoading) {
      if (revertAuthCheck) return <Navigate to={to} />;
      return <>{children}</>;
    } else return <PageLoader />;
  } else return <>{children}</>;
};

export default memo(RouteGuard);
