import {
  collection,
  CollectionReference,
  doc,
  DocumentReference,
  FirestoreError,
  getDoc,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  Timestamp,
  updateDoc,
  where,
  writeBatch
} from 'firebase/firestore';
import { NotificationT } from 'types/notification.d';
import { db } from 'utils/Firebase';

export function fetchSingleOrder(orderId: string, onNext: (order: OrderT | null) => void) {
  const orderQuery = doc(db, 'orders', `${orderId}`) as DocumentReference<OrderT>;
  return onSnapshot(orderQuery, { includeMetadataChanges: true }, (querySnapshot) => {
    if (!querySnapshot.metadata.hasPendingWrites) {
      if (querySnapshot.exists()) onNext(querySnapshot.data());
      else onNext(null);
    }
  });
}

export function fetchOrderProducts(orderId: string, onNext: (products: ProductT[]) => void) {
  const productQuery = collection(
    db,
    `orders/${orderId}/lineItems`
  ) as CollectionReference<ProductT>;
  return onSnapshot(productQuery, { includeMetadataChanges: true }, (querySnapshot) => {
    if (!querySnapshot.metadata.hasPendingWrites) {
      const products: ProductT[] = [];
      if (querySnapshot.size > 0) {
        querySnapshot.forEach((doc) => {
          products.push({ ...doc.data(), id: doc.id });
        });
      }
      onNext(products);
    }
  });
}

export async function updateOrderStatus(orderId: string, status: OrderStatusT) {
  const orderRef = doc(db, `orders`, `${orderId}`) as DocumentReference<OrderT>;
  return await updateDoc(orderRef, {
    status: status
  });
}

export async function updateProduct(orderId: string, productId: string, product: ProductT) {
  const productRef = doc(
    db,
    `orders/${orderId}/lineItems`,
    `${productId}`
  ) as DocumentReference<ProductT>;
  return await updateDoc(productRef, product);
}

export async function fetchUserFcmTokens(userId?: string) {
  if (!userId) return;
  const docRef = doc(db, 'fcmTokens', `${userId}`) as DocumentReference<{ tokens: string[] }>;
  return new Promise<string[]>(async (resolve, reject) => {
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) resolve(docSnap.data()?.tokens ?? []);
    else reject([]);
  });
}

export function addUserFcmToken(tokens: string[] = [], userId?: string) {
  if (!userId) return;
  const fcmTokensRefs = doc(db, `fcmTokens`, `${userId}`);
  setDoc(fcmTokensRefs, { tokens }, { merge: true }).catch((e) => {
    console.error(e);
  });
}

export function fetchUserNotifications(
  userId: string,
  storeId: string,
  onNext: (notifications: NotificationT[] | null) => void,
  onError?: (error: FirestoreError) => void
) {
  const notificationDoc = collection(
    db,
    `notifications/${userId}/orders`
  ) as CollectionReference<NotificationT>;
  let queryData = query(
    notificationDoc,
    where('isOrderViewed', '==', false),
    where('storeId', '==', storeId),
    orderBy("created", "asc")
  );
  return onSnapshot(
    queryData,
    { includeMetadataChanges: true },
    (querySnapshot) => {
      if (!querySnapshot.metadata.hasPendingWrites) {
        const notifications: NotificationT[] = [];
        querySnapshot.docs.forEach((doc) => {
          notifications.push({ ...doc.data(), orderId: doc.id });
        });
        
        onNext(notifications);
      }
    },
    onError
  );
}
export async function updateUserNotifications(userId: string, notifications: NotificationT[]) {
  const batch = writeBatch(db);
  notifications?.forEach((notification) => {
    const notificationDoc = doc(
      db,
      `notifications/${userId}/orders`,
      notification.orderId
    ) as DocumentReference<NotificationT>;
    batch.update(notificationDoc, { modified: Timestamp.now(), isOrderViewed: true });
  });
  await batch.commit();
}
