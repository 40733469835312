/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useEffect, useState } from 'react';
import { auth } from 'utils/Firebase';
import { Unsubscribe, User } from 'firebase/auth';
import { firebaseAuthentication, signOutUser } from 'service/authentication';

export interface UserProfileT {
  sub: string;
  name: string;
  given_name: string;
  family_name: string;
  fullaccess: string;
  orghierarchynodeid: string;
  allowedStores?: string | string[]
}

interface UserContextInterfaceT {
  user: User | null;
  logOut: Function;
  isLoading: boolean;
  userDataPresent: boolean;
  setLoading: (isLoading: boolean) => void;
  profile: UserProfileT | null;
}

interface AuthContextProviderProps {
  children: React.ReactNode;
}
export const UserAuthContext = createContext<UserContextInterfaceT>({
  user: null,
  logOut: () => {},
  isLoading: true,
  userDataPresent: false,
  setLoading: () => {},
  profile: null,
});

export function UserAuthContextProvider({ children, ...rest }: AuthContextProviderProps) {
  const [state, changeState] = useState<{
    userDataPresent: boolean;
    user: UserContextInterfaceT['user'];
    listener: Unsubscribe | null;
    isLoading: boolean;
    profile: UserContextInterfaceT['profile'];
  }>({
    userDataPresent: false,
    user: null,
    listener: null,
    isLoading: false,
    profile: null
  });

  useEffect(() => {
    const unsubscribe = firebaseAuthentication((profile) => {
      changeState((state) => ({ ...state, profile }));
      setLoading(false);
    });
    if (!state.listener)
      changeState({
        ...state,
        isLoading: true,
        listener: auth.onAuthStateChanged((user) =>
          changeState((oldState: any) => ({ ...oldState, userDataPresent: true, user: user }))
        )
      });

    return () => {
      if (state.listener) state.listener();
      unsubscribe();
    };
  }, []);

  function setLoading(isLoading = false) {
    changeState((state) => ({ ...state, isLoading }));
  }

  return (
    <UserAuthContext.Provider
      data-testid="provider"
      {...rest}
      value={{
        user: state.user,
        userDataPresent: state.userDataPresent,
        logOut: signOutUser,
        isLoading: state.isLoading,
        setLoading,
        profile: state.profile,
      }}>
      {children}
    </UserAuthContext.Provider>
  );
}
