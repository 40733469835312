import { Box, BoxProps, Menu, MenuList, MenuButton } from '@chakra-ui/react';
import 'styles/components/_popout.scss';

export type PopupItemT = {
  value: string;
  style: BoxProps;
};
interface PropsType {
  children: React.ReactNode;
  items: Array<PopupItemT>;
}

const Popup = ({ children, items }: PropsType) => {
  return (
    <Menu offset={[0, 0]} id="profile-container">
      <MenuButton
        bg='transparent'
        px='22px'
        h='100%'
        rounded='none'
        fontWeight='semibold'
        color='white'
        _hover={{ bg: 'white', color: '#000', fill: '#000' }}
        aria-label='Notification'
        boxShadow='none'
        id="profile-icon"
      >
        {children}
      </MenuButton>
      <MenuList>
        {items.map(({ value, style, ...rest }) => {
          return (
            <Box className='popup__item' key={value} {...rest}>
              {value}
            </Box>
          );
        })}
      </MenuList>
    </Menu>
  );
};

export default Popup;
