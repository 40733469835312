import {
  Box,
  Center,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
} from '@chakra-ui/react';
import { DynamicIcon } from 'components/DynamicIcon/DynamicIcon';
import React from 'react';
import 'styles/components/_menuDrawer.scss';

interface PropsType {
  isOpen: boolean;
  onClose: () => void;
  onOpen: () => void;
  size: any;
  header: React.ReactNode;
  content: React.ReactNode;
}
const CustomDrawer = ({
  isOpen,
  onOpen,
  onClose,
  size,
  header,
  content,
}: PropsType) => {
  return (
    <Drawer placement='left' size={size} onClose={onClose} isOpen={isOpen}>
      <DrawerOverlay background='rgba(0, 0, 0, 0.7)' />
      <DrawerContent background='transparent' boxShadow='none'>
        <Flex height='100%' justify='flex-start'>
          <Box background='#fff' height='100%' width='calc(100% - 50px)'>
            <DrawerHeader px='36px' py='37px'>
              {header}
            </DrawerHeader>
            <DrawerBody px='36px'>{content}</DrawerBody>
          </Box>
          <Box onClick={onClose}>
            <Box
              width='50px'
              height='60px'
              onClick={onClose}
              data-testid='closeIcon'
            >
              <Center w='100%' h='100%'>
                <DynamicIcon
                  icon='Vsc/VscClose'
                  ic_size='2.3rem'
                  ic_color='#fff'
                  style={{ cursor: 'pointer' }}
                />
              </Center>
            </Box>
          </Box>
        </Flex>
      </DrawerContent>
    </Drawer>
  );
};

export default CustomDrawer;
