export const colors = {
  warning: {
    400: '#ffeb3b',
  },
  mainColorDarkest: '#0a3e67',
  mainTextColor: '#0245ff',
  mainColor: {
    lightest: '#eff5fb',
    lighter: '#dbe4f1',
    light: '#d2e3ff',
    base: '#2962ff',
    dark: '#1565c0',
    darker: '#0d47a1',
    darkest: '#0a3e67',
  },
  background: {
    1: '#f6f7f9',
    2: '#fafbfc',
    3: '#e7eaef',
  },
  grey: {
    base: '#c8d4dd',
    dark: '#96a0ab',
    darker: '#384049',
  },
  white: '#fff',
  text: {
    primary: '#222b35',
    secondary: '#5c6b7a',
  },
  notification: {
    light: '#fff2de',
    base: '#c54400',
  },
  info: {
    base: '#ccfef3',
    dark: '#a9f4e4',
  },
  progress: {
    base: '#7bf264',
    darker: '#134d13',
  },
  error: {
    light: '#fcd9d7',
    base: '#c7180b',
    dark: '#ab000d',
  },
  active: {
    base: '#0c7d6c',
    dark: '#005b4f',
  },
  partially: {
    base: '#afb42b',
    dark: '#999900',
  },
  picking: {
    base: '#ff7429',
    dark: '#ff5900',
  },
  draft: {
    base: '#eb004e',
    dark: '#c70042',
  },
  processing: {
    base: '#4fc3f7',
    dark: '#039be5',
  },
  placed: {
    base: '#4dd0e1',
    dark: '#00acc1',
  },
  substituted: {
    base: '#ab41be',
    dark: '#790e8b',
  },
  dropshadow: '#1a3851',
};
