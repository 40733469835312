/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Flex } from '@chakra-ui/react';
import { PropsWithChildren, useEffect } from 'react';
import AppHeader from './AppHeader';
import { useRecoilState } from 'recoil';
import { selectedStoreIdState } from 'recoil/selectedStore/selectedStore.atom';
import { doc, DocumentReference, getDoc } from 'firebase/firestore';
import { db } from 'utils/Firebase';
import { useAuth } from 'utils/hooks';
import { IStore } from 'pages/store-list/store.interface';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';

interface Props extends PropsWithChildren {}

export default function AppLayout(props: Props) {
  const { user } = useAuth();
  const [selectedStore, setSelectedStore] = useRecoilState(selectedStoreIdState);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (user?.uid && !selectedStore?.id) {
      const userInfoRef = doc(db, 'users', user?.uid);
      getDoc(userInfoRef)
        .then((docSnap) => {
          const userInfo = docSnap.data();
          if (userInfo?.selectedStore) {
            getSelectedStore(userInfo?.selectedStore);
          } else {
            if (
              matchPath('/stores/:storeId/orders', pathname) ||
              matchPath('/stores/:storeId/orders/:orderId', pathname)
            ) {
              navigate('/stores');
            }
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [user]);

  const getSelectedStore = (id: string) => {
    const selectedStoreInfoRef = doc(db, 'stores', id) as DocumentReference<IStore>;
    getDoc(selectedStoreInfoRef)
      .then((docSnap) => {
        if (docSnap.exists()) {
          const selectedStore = docSnap.data();
          selectedStore.id = docSnap.id;
          setSelectedStore(selectedStore);
        } else {
          if (
            matchPath('/stores/:storeId/orders', pathname) ||
            matchPath('/stores/:storeId/orders/:orderId', pathname)
          ) {
            navigate('/stores');
          }
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <Flex flexDir="column" h="100vh">
      <AppHeader />
      <Box marginTop="60px" as="main" flex="1" minHeight="0">
        {props.children}
      </Box>
    </Flex>
  );
}
