import { lazy } from 'react';

const Login = lazy(() =>
  import('pages/auth/Login').then((module) => ({
    default: module.LoginPage,
  }))
);

const Welcome = lazy(() =>
  import('pages/welcome').then((module) => ({
    default: module.Welcome,
  }))
);

const Orders = lazy(() =>
  import('pages/orders').then((module) => ({
    default: module.Orders,
  }))
);

const OrderDetails = lazy(() =>
  import('pages/orderDetails').then((module) => ({
    default: module.OrderDetails,
  }))
);

const StoreList = lazy(() =>
  import('pages/store-list').then((module) => ({
    default: module.StoreList,
  }))
);

const ResourceNotFound = lazy(() =>
  import('pages/resourceNotFound').then((module) => ({
    default: module.ResourceNotFound,
  }))
);

export { Login, Welcome, Orders, OrderDetails, ResourceNotFound, StoreList };
