// main theme entrypoint
import { extendTheme } from '@chakra-ui/react';
import { breakpoints } from './constant';
import { colors } from './colors';
import { fonts } from './fonts';
import { styles } from './styles';
import { buttonTheme } from './components/button';
import typography from './foundations/typography';
import { modalTheme } from './components/modal';
const overrides = {
  breakpoints,
  colors,
  fonts,
  styles,
  ...typography,
  components: {
    Button: buttonTheme,
    Modal: modalTheme
  }
};

export default extendTheme(overrides);
