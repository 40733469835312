export const modalTheme = {
  // sizes: {
  //   sm: {
  //     h: '700px',
  //     w: '1000px',
  //     px: '2.3rem'
  //   },
  //   md: {
  //     w: '1000px',
  //     px: '2.3rem'
  //   },
  //   lg: {
  //     w: '1000px',
  //     px: '2.3rem'
  //   },
  //   xl: {
  //     background: 'green'
  //   }
  // },
  baseStyle: {
    dialog: {}
  }
};
