import PageLoader from 'components/pageLoader/PageLoader';
import AppLayout from 'container/layout/AppLayout';
import { Suspense } from 'react';
import { RouteObject } from 'react-router-dom';
import { ResourceNotFound, Welcome, OrderDetails, StoreList, Orders } from './routePages';
import { routePaths } from './routePaths';

type Route = {
  path: string;
  name: string;
  isProtected?: boolean;
  children?: Route[];
  layout?: JSX.Element;
  to?: string;
  revertAuthCheck?: boolean;
};

export type RouteT = Route & RouteObject;

function suspenseRoute(component: JSX.Element) {
  return <Suspense fallback={<PageLoader />}>{component}</Suspense>;
}

export const routes: RouteT[] = [
  // {
  //   ...routePaths.login,
  //   element: suspenseRoute(
  //     <RouteGuard revertAuthCheck to={routePaths.welcome.path}>
  //       <Login />
  //     </RouteGuard>
  //   ),
  //   isProtected: true
  // },
  {
    ...routePaths.welcome,
    element: suspenseRoute(<Welcome />),
    isProtected: true,
    layout: <AppLayout />
  },
  {
    ...routePaths.storeList,
    element: suspenseRoute(<StoreList />),
    isProtected: true,
    layout: <AppLayout />
  },

  {
    ...routePaths.orders,
    element: suspenseRoute(<Orders />),
    isProtected: true,
    layout: <AppLayout />
  },

  {
    ...routePaths.orderDetails,
    element: suspenseRoute(<OrderDetails />),
    isProtected: true,
    layout: <AppLayout />
  },

  {
    ...routePaths.resourceNotFound,
    element: suspenseRoute(<ResourceNotFound />),
    isProtected: false
  }
];
