import { Badge, Box, IconButton, Spinner } from '@chakra-ui/react';
import { ComponentProps } from 'react';

interface Props extends ComponentProps<'button'> {
  badgeCount?: number;
  isLoading?: boolean;
}

export default function HeaderIconButton(props: Props) {
  const { children, className, badgeCount = 0, isLoading = false, ...rest } = props;
  return (
    <Box as="span" position="relative" height="100%">
      <IconButton
        bg="transparent"
        p="11px"
        h="100%"
        rounded="none"
        fontWeight="semibold"
        color="white"
        _hover={{ bg: 'white', color: '#000', fill: '#000' }}
        aria-label="Notification"
        boxShadow="none"
        {...rest}>
        {children}
      </IconButton>
      {(isLoading || !!badgeCount) && (
        <Badge
          position="absolute"
          rounded="xl"
          fontSize="md"
          size="sm"
          left="5px"
          top="11px"
          bgColor="notification.base"
          color="white"
          padding="3px"
          minWidth="19px"
          lineHeight="1"
          fontWeight="bold"
          display="inline-flex"
          alignItems="center"
          justifyContent="center">
          {isLoading ? <Spinner size="xs" /> : badgeCount}
        </Badge>
      )}
    </Box>
  );
}
