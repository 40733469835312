import { useEffect, useState } from 'react';

export function useSingleAndDoubleClick(
  actionSinleClick: (e: any) => void,
  actionDoubleClick?: () => void,
  data?: any,
  delay = 150
) {
  const [click, setClick] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (click === 1) actionSinleClick(data);
      setClick(0);
    }, delay);

    if (click === 2) !!actionDoubleClick && actionDoubleClick();

    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [click]);

  return () => setClick((prev) => prev + 1);
}
