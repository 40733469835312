//all global style overrides

export const styles = {
  global: {
    body: {
      fontFamily: `'Roboto', sans-serif`,
      minH: '100vh',
    },
    html: {
      minH: '100vh',
    },
  },
};
