import axios, { AxiosRequestConfig } from 'axios';
import * as Sentry from '@sentry/react';
import { auth } from 'utils/Firebase';

// const baseUrl = process.env.REACT_APP_BASE_URL;
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.headers.common['Access-Control-Allow-Headers'] = 'Content-Type, Authorization';
axios.defaults.headers.common['Access-Control-Allow-Methods'] = 'GET, POST, PUT, DELETE,OPTIONS';
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.common['Content-Type'] = 'application/json';
// axios.defaults.baseURL = baseUrl;


axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      Sentry.setUser(null);
      sessionStorage.clear();
      auth.signOut();
      window.location.href = `/api/auth/logout`;
    } else if (error.response && error.response.data && error.response.data.location) {
      window.location.href = error.response.data.location;
    } else {
      return Promise.reject(error);
    }
  }
);

const api = {
  get: <T>(url: string, params?: object) =>
    axios.get<T>(url, {
      ...params
    }),
  post: <T>(url: string, data?: any, config?: AxiosRequestConfig) =>
    axios.post<T>(url, data, config),

  patch: <T>(url: string, data: any) => axios.patch<T>(url, data),
  put: <T>(url: string, data: any) => axios.put<T>(url, data),
  delete: <T>(url: string) => axios.delete<T>(url),
  getCancelToken: () => axios.CancelToken.source()
};

export default api;
