import { Box, List, ListItem, Stack, Text, useDisclosure } from '@chakra-ui/react';
import MenuDrawer from 'components/MenuDrawer/MenuDrawer';
import { useLocation, useNavigate } from 'react-router-dom';
import { GlobalConstant } from 'static/constants';
import { ReactComponent as MenuIcon } from 'assets/icons/icon-menu.svg';
import { useAuth } from 'utils/hooks';
import { useRecoilValue } from 'recoil';
import {selectedStoreIdState} from 'recoil/selectedStore/selectedStore.atom';
import { useTranslation } from 'react-i18next';

const SIZE = {
  base: 'full',
  sm: 'sm',
  lg: 'md'
};

interface INavigateToMenuParams {
  name: string;
  path: string;
}
const HamMenuHeader = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const selectedStore = useRecoilValue(selectedStoreIdState);
  const { t } = useTranslation('common');
  const { profile } = useAuth();
  return (
    <Stack spacing=".5rem">
      <Text fontSize="2xl" fontWeight="light" color="mainTextColor" lineHeight="normal">
        {t('sidebar.greeting', { name: profile?.name })}
      </Text>
      <Text fontSize="xl" fontWeight="bold" color="grey.darker" lineHeight="1.875rem">
        {selectedStore?.name}
      </Text>
    </Stack>
  );
};

const HamMenuContent = ({ onClose }: { onClose: () => void }) => {
  const { t, i18n } = useTranslation('common');
  const navigate = useNavigate();
  const selectedStore = useRecoilValue(selectedStoreIdState)?.id?.replaceAll(' ', '-');
  const { pathname } = useLocation();

  const navigateToMenu = (item: INavigateToMenuParams) => {
    if (pathname !== item.path) {
      if (item.name === 'Orders') {
        navigate(`/stores/${selectedStore}/orders`);
      } else {
        navigate(item.path);
      }
    }
    onClose();
  };

  return (
    <List spacing="1.938rem">
      {GlobalConstant.MENU_ITEM.filter((item) => item.name === 'Stores' || selectedStore).map(
        (item) => {
          const translationKey = `sidebar.${item.name.toLowerCase()}`;
          return (
            <ListItem key={item.name} onClick={() => navigateToMenu(item)}>
              <Text
                fontSize="lg"
                fontWeight="normal"
                color="text.primary"
                cursor="pointer"
                lineHeight="1.938rem"
                data-testid={item.name}>
                {i18n.exists(`common:${translationKey.replace('.', ':')}`) ? t(translationKey) : ''}
              </Text>
            </ListItem>
          );
        }
      )}
    </List>
  );
};
const HamMenu = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Box onClick={() => onOpen()} data-testid="hamIcon" cursor="pointer" color="white">
        <MenuIcon height="2rem" />
      </Box>
      <MenuDrawer
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        size={SIZE}
        header={<HamMenuHeader />}
        content={<HamMenuContent onClose={onClose} />}
      />
    </>
  );
};

export default HamMenu;
